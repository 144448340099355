import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Carousel      from '../components/Carousel';
import Spacer        from '../components/Spacer';
import Heading       from '../components/Heading';
import Wrapper       from '../components/Wrapper';
import DefaultLayout from '../layouts/Default';

function ThanksPage(props) {
  return (
    <DefaultLayout location={props.location}>
      <Carousel>
        {props.data.headerImages.edges.map((image) => (
          <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading>Nachricht erfolgreich versendet</Heading>
        {props.location.state ?
          <div>
            <p>Vielen Dank {props.location.state.name} für Ihre Nachricht.</p>
            <p>Wir werden uns bei Ihnen unter {props.location.state.email} so schnell es geht melden.</p>
          </div>
          :
          <div>
            <p>Vielen Dank für Ihre Nachricht.</p>
            <p>Wir werden uns bei Ihnen so schnell es geht melden.</p>
          </div>
        }
        <Spacer/>
      </Wrapper>
    </DefaultLayout>
  )
}

export default ThanksPage;

export const query = graphql`
  query ThanksPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/contact"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
  }
`;