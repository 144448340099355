import styled    from 'styled-components';
import PropTypes from 'prop-types';

const Heading = styled.h1`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  img {
    margin-bottom: 0;
  }
  &:after {
    background-color: ${props => props.color ? props.color : props.theme.colors.brandColor};
    display: block;
    content: "";
    height: 1px;
    width: 120px;
    margin: 15px auto 20px;
    position: relative;
  }
`;

Heading.propTypes = {
  color: PropTypes.string,
};

export default Heading;
