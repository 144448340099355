import React                    from 'react';
import PropTypes                from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Helmet                   from 'react-helmet';
// TODO remove
/* eslint-disable */
function SEO(props) {
  return (
    <StaticQuery
      query={graphql`
        query SEOQuery {
          seo: allConfigJson {
            edges {
              node {
                ...SEO
              }
            }
          }
        }
      `}
      render={(data) => {
        const seo = data.seo.edges[0].node.seo;
        const { itemSEO, itemPath, itemInfo } = props;
        const schemaOrgJSONLD = [
          {

          }
        ];
        return (
          <Helmet>
            <html lang={seo.lang}/>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description}/>
          </Helmet>
        );
      }}/>
  )
}

SEO.properTypes = {
  item: PropTypes.object,
};

export default SEO;
