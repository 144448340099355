import React                                  from 'react';
import PropTypes                              from 'prop-types';
import { StaticQuery, graphql, PageRenderer } from 'gatsby'
import { injectGlobal }                       from 'styled-components';
import { Airport, Header, Footer }            from '@pgegenfurtner/airport';

import SEO   from '../components/SEO';
import Modal from '../components/Modal'

let windowWidth;

class DefaultLayout extends React.Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
    isModal: PropTypes.bool.isRequired,
    items: PropTypes.array,
  };

  render() {
    const { children } = this.props;
    let isModal = false;
    if (!windowWidth && typeof window !== 'undefined') {
      windowWidth = window.innerWidth;
    }
    if (this.props.isModal && windowWidth > 750) {
      isModal = true;
    }
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            allConfigJson {
              edges {
                node {
                  config {
                    siteTitle
                    siteLogo
                  }
                  ...Contact
                  ...Theme
                  ...Menus
                }
              }
            }
          }
        `}
        render={(data) => {
          const { menus, config, theme, contact } = data.allConfigJson.edges[0].node;

          injectGlobal`
            a {
              color: ${theme.colors.brandColor};
            }
          `;

          let pathname = this.props.location.pathname.split('/');
          pathname.pop();
          if (isModal) {
            return (
              <Airport theme={theme}>
                <>
                  <PageRenderer location={{ pathname: pathname.join('/') }}/>
                  <Modal isOpen={true} location={this.props.location} items={this.props.items}>
                    {children}
                  </Modal>
                </>
              </Airport>
            )
          }

          return (
            <Airport theme={theme}>
              <React.Fragment>
                <SEO/>
                <Header
                  menu={menus.primary}
                  logo={config.siteLogo}
                  title={config.siteTitle}
                />

                <main>
                  {children}
                </main>

                <Footer navigation={menus.footer} contact={contact} social={menus.social}/>
              </React.Fragment>
            </Airport>
          )
        }}
      />
    )
  }
}

DefaultLayout.defaultProps = {
  isModal: false,
};

export default DefaultLayout;
