import React                                        from 'react';
import ReactModal                                   from 'react-modal';
import mousetrap                                    from 'mousetrap';
import PropTypes                                    from 'prop-types';
import findIndex                                    from 'lodash/findIndex';
import { navigate }                                 from 'gatsby';
import { FaCaretRight, FaCaretLeft, FaTimesCircle } from 'react-icons/lib/fa';

const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 99,
  },
  content: {
    position: 'absolute',
    border: 'none',
    background: 'none',
    padding: 0,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: 'auto',
  }
};

class Modal extends React.Component {

  constructor(props) {
    ReactModal.setAppElement('#___gatsby');
    super(props);
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    location: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  };

  componentDidMount() {
    mousetrap.bind('left', () => this.previous());
    mousetrap.bind('right', () => this.next());
    mousetrap.bind('esc', () => this.close());
    this.htmlElement = document.querySelector('html');
    this.htmlElement.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    mousetrap.unbind('left');
    mousetrap.unbind('right');
    mousetrap.unbind('esc');
    this.htmlElement.style.overflowY = 'auto';
  }

  findCurrentIndex() {
    return findIndex(
      this.props.items,
      item => item.node.fields.slug === this.props.location.pathname.split('/')[3]
    );
  }

  next(e) {
    if (e) {
      e.stopPropagation();
    }
    const currentIndex = this.findCurrentIndex();
    if (currentIndex || currentIndex === 0) {
      const items = this.props.items;
      let nextItem;
      // Wrap around if at end.
      if (currentIndex + 1 === items.length) {
        nextItem = items[0]
      } else {
        nextItem = items[currentIndex + 1]
      }
      navigate(nextItem.node.fields.fullPath, {
        state: {
          isModal: true,
        }
      })
    }
  }

  previous(e) {
    if (e) {
      e.stopPropagation();
    }
    const currentIndex = this.findCurrentIndex();
    if (currentIndex || currentIndex === 0) {
      const items = this.props.items;
      let previousItem;
      // Wrap around if at start.
      if (currentIndex === 0) {
        previousItem = items.slice(-1)[0]
      } else {
        previousItem = items[currentIndex - 1]
      }
      navigate(previousItem.node.fields.fullPath, {
        state: {
          isModal: true,
        }
      })
    }
  }

  close(e) {
    if (e) {
      e.stopPropagation();
    }
    navigate(this.props.location.pathname.split('/', 3).join('/'), {
      state: {
        isModal: false,
      }
    })
  }

  render() {
    return (
      <ReactModal isOpen={this.props.isOpen}
                  onRequestClose={e => this.close(e)}
                  style={modalStyle}
                  contentLabel="Modal">
        <div
          style={{
            display: 'flex',
            position: 'relative',
            height: '100vh',
          }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
          }}>
            <FaCaretLeft onClick={e => this.previous(e)} style={{
              fontSize: '50px',
              color: 'rgba(255,255,255,0.7)',
              cursor: 'pointer',
              userSelect: 'none',
            }}/>
            {this.props.children}
            <FaCaretRight onClick={e => this.next(e)} style={{
              fontSize: '50px',
              color: 'rgba(255,255,255,0.7)',
              cursor: 'pointer',
              userSelect: 'none',
            }}/>
          </div>
          <FaTimesCircle onClick={e => this.close(e)} style={{
            cursor: 'pointer',
            color: 'rgba(255,255,255,0.7)',
            fontSize: '30px',
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}/>
        </div>
      </ReactModal>
    )
  };
}

export default Modal;
