import styled    from 'styled-components';
import PropTypes from 'prop-types';

const Spacer = styled.div`
  height: ${({ height }) => height}px;
  width: 100%;
`;

Spacer.propTypes = {
  height: PropTypes.number,
};

Spacer.defaultProps = {
  height: 60,
};

export default Spacer;
