import styled    from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 90%;
  max-width: ${props => props.width ? props.width : props.theme.dimensions.wrapperWidth}px;
  margin: auto;
`;

Wrapper.propTypes = {
  width: PropTypes.number,
};

export default Wrapper;
